import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppConfig, AppStatus, DashboardHostingPlatform, HeaderLogoSize, RegistrationChannel } from "../../../models";

const appConfigInitialState: AppConfig = {
  name: '',
  partnerType: '',
  domain: '',
  companyId: '',
  companyWebsite: '',
  address: '',
  pinCode: '',
  city: '',
  headerLogoSize: HeaderLogoSize.NORMAL,
  logo: {
    smallLogo: {
      url: '',
      width: 0,
      height: 0
    },
    largeLogo: {
      url: '',
      width: 0,
      height: 0
    }
  },
  color: {
    primary: '',
    secondary: '',
    accent: '',
    headingFontColor: '',
    bodyFontColor: ''
  },
  supportDetails: {
    email: '',
    phone: ''
  },
  isBrandContestActive: true,
  closureMessage: '',
  appStatus: AppStatus.LIVE,
  redirectingTo: '',
  leagueName: '',
  dashboardHostedOn: DashboardHostingPlatform.NONE,
  registrationChannel: RegistrationChannel.SELF,
  externalRegistrationDetails: {
    url: ''
  },
  isOneContestBrand: false,
  showBrandTermsAndConditionsModal: false,
  checkContestEnrollmentOnLogin: false,
  continueJourneyButtonText: '',
  continueJourneyRedirectionLink: '',
  showUploadSubmissionPopup: false,
  submissionUploadStartDate: '',
  isRegistrationClosed: false,
  registrationClosedMessageHTML: '',
  isResultsAvailable: false,
  resultPageUrl: '',
  showOnlyPartnersLogoInHeader: false,
  allowEnrollmentWithOutRegistrationFormFillUp: false,
}

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: appConfigInitialState,
  reducers: {
    setAppConfig: (state: AppConfig, action: PayloadAction<AppConfig>) => {
      return {
        ...state,
        ...action.payload
      };
    },
    setPartnerDomain: (state: AppConfig, action: PayloadAction<string>) => {
      return {
        ...state,
        domain: action.payload
      }
    },
  }
});

export const selectAppConfig = (state: { appConfig: AppConfig }) => state.appConfig;
export const selectPartnerLogo = (state: { appConfig: AppConfig }) => state.appConfig.logo;
export const selectPartnerColor = (state: { appConfig: AppConfig }) => state.appConfig.color;
export const selectPartnerSupportDetails = (state: { appConfig: AppConfig }) => state.appConfig.supportDetails;
export const selectPartnerDomain = (state: { appConfig: AppConfig }) => state.appConfig.domain;
export const selectIsBrandContestActive = (state: { appConfig: AppConfig }) => state.appConfig.isBrandContestActive;
export const selectClosureMessage = (state: { appConfig: AppConfig }) => state.appConfig.closureMessage;
export const selectAppStatus = (state: { appConfig: AppConfig }) => state.appConfig.appStatus;
export const selectAppsRedirectionLink = (state: { appConfig: AppConfig }) => state.appConfig.redirectingTo;
export const selectLeagueName = (state: { appConfig: AppConfig }) => state.appConfig.leagueName;
export const selectPartnerType = (state: { appConfig: AppConfig }) => state.appConfig.partnerType;
export const selectDashboardHostedOn = (state: { appConfig: AppConfig }) => state.appConfig.dashboardHostedOn;
export const selectRegistrationChannel = (state: { appConfig: AppConfig }) => state.appConfig.registrationChannel;
export const selectIsOneContestBrand = (state: { appConfig: AppConfig }) => state.appConfig.isOneContestBrand;
export const selectShowBrandTermsAndConditionsModal = (state: { appConfig: AppConfig }) => state.appConfig.showBrandTermsAndConditionsModal;
export const selectCheckContestEnrollmentOnLogin = (state: { appConfig: AppConfig }) => state.appConfig.checkContestEnrollmentOnLogin;
export const selectSubmissionUploadStartDate = (state: { appConfig: AppConfig }) => state.appConfig.submissionUploadStartDate;
export const selectIsRegistrationClosed = (state: { appConfig: AppConfig }) => state.appConfig.isRegistrationClosed;
export const selectAllowEnrollmentWithOutRegistrationFormFillUp = (state: { appConfig: AppConfig }) => state.appConfig.allowEnrollmentWithOutRegistrationFormFillUp;

export const { setAppConfig, setPartnerDomain } = appConfigSlice.actions;

export default appConfigSlice.reducer;