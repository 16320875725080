import aes from 'crypto-js/aes'
import encUtf8 from 'crypto-js/enc-utf8'
import { UserPreferencesForBrandTermsAndConditionsModal } from '../models/index';

const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'
const BASIC_USER_DATA = 'basicUserData'
const PHONE_NUMBER = 'phoneNumber'
const USER_PREFERENCES_FOR_BRAND_TERMS_AND_CONDITIONS_MODAL = 'userPreferencesForBrandTermsAndConditionsModal'
const ANONYMOUS_USER_ID = 'anonymousUserId'


type localStorageKey = typeof ACCESS_TOKEN | typeof REFRESH_TOKEN | typeof BASIC_USER_DATA | typeof PHONE_NUMBER | typeof USER_PREFERENCES_FOR_BRAND_TERMS_AND_CONDITIONS_MODAL | typeof ANONYMOUS_USER_ID

const secretKey = process.env.REACT_APP_API_TOKEN_SECRET_KEY!!

const encryptToken = (token: string) => {
  return aes.encrypt(token, secretKey).toString()
}

const decryptToken = (cipherText: string) => {
  const bytes = aes.decrypt(cipherText, secretKey)
  const originalText = bytes.toString(encUtf8)
  return originalText
}

const LocalStorage = {
  set: {
    accessToken: (accessToken: string) => {
      window.localStorage.setItem(ACCESS_TOKEN, encryptToken(accessToken))
    },
    refreshToken: (refreshToken: string) => {
      window.localStorage.setItem(REFRESH_TOKEN, encryptToken(refreshToken))
    },
    basicUserData: (basicData: Record<string, any>) => {
      window.localStorage.setItem(BASIC_USER_DATA, JSON.stringify(basicData))
    },
    userPreferencesForBrandTermsAndConditionsModal: (value: UserPreferencesForBrandTermsAndConditionsModal) => {
      window.localStorage.setItem(USER_PREFERENCES_FOR_BRAND_TERMS_AND_CONDITIONS_MODAL, JSON.stringify(value))
    },
    anonymousUserId: (anonymousUserId: string) => {
      window.localStorage.setItem(ANONYMOUS_USER_ID, encryptToken(anonymousUserId))
    },
    mixpanelDistinctId: (distinctId: string) => {
      window.localStorage.setItem('mixpanelDistinctId', distinctId)
    }
  },
  get: {
    accessToken: () => {
      try {
        const accessToken = decryptToken(
          window.localStorage.getItem(ACCESS_TOKEN)!!
        )
        return accessToken
      } catch (err) {
        return null
      }
    },
    refreshToken: () => {
      try {
        const refreshToken = decryptToken(window.localStorage.getItem(REFRESH_TOKEN)!!)
        return refreshToken
      } catch (err) {
        return null
      }
    },
    basicUserData: () =>
      JSON.parse(window.localStorage.getItem(BASIC_USER_DATA)!!),
    userPreferencesForBrandTermsAndConditionsModal: () => {
      return JSON.parse(window.localStorage.getItem(USER_PREFERENCES_FOR_BRAND_TERMS_AND_CONDITIONS_MODAL)!!) as UserPreferencesForBrandTermsAndConditionsModal
    },
    anonymousUserId: () => {
      try {
        const anonymousUserId = decryptToken(
          window.localStorage.getItem(ANONYMOUS_USER_ID)!!
        )
        return anonymousUserId
      } catch (err) {
        return null
      }
    },
    mixpanelDistinctId: () => {
      return window.localStorage.getItem('mixpanelDistinct')
    }
  },
  clear: () => {
    window.localStorage.removeItem(ACCESS_TOKEN)
    window.localStorage.removeItem(REFRESH_TOKEN)
    window.localStorage.removeItem(PHONE_NUMBER)
  },
  clearBasicUserData: () => {
    window.localStorage.removeItem(BASIC_USER_DATA)
  },
  clearLocalStorageItem: (key: localStorageKey) => {
    window.localStorage.removeItem(key)
  }
}

export default LocalStorage
