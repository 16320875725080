import { AppConfig, AppStatus, ColorType, DashboardHostingPlatform, HeaderLogoSize, LogoType, RegistrationChannel } from "../../models"


const getColorCodeFromResponse = (colorResponse: any[], colorType: ColorType) => {
  const color = colorResponse.find((color: any) => color.color_type === colorType)
  return color?.color_hex_code || ''
}

const getLogoFromResponse = (logoResponse: any[], logoType: LogoType) => {
  const logo = logoResponse.find((logo: any) => logo.logo_type === logoType)

  return {
    url: logo?.logo || '',
    width: logo?.width || 0,
    height: logo?.height || 0
  }
}

export const convertAppConfigResponseToAppConfig = (response: any): AppConfig => {
  return {
    name: response.name,
    partnerType: response.partner_type,
    domain: response.domain,
    companyId: response.company_id,
    companyWebsite: response.company_website,
    address: response.address,
    pinCode: response.pin_code,
    city: response.city,
    headerLogoSize: response.header_logo_size || HeaderLogoSize.NORMAL,
    logo: {
      smallLogo: getLogoFromResponse(response.logo, LogoType.Small),
      largeLogo: getLogoFromResponse(response.logo, LogoType.Large)
    },
    color: {
      primary: getColorCodeFromResponse(response.colour, ColorType.Primary),
      secondary: getColorCodeFromResponse(response.colour, ColorType.Secondary),
      accent: getColorCodeFromResponse(response.colour, ColorType.Accent),
      headingFontColor: getColorCodeFromResponse(response.colour, ColorType.HEADING_FONT_COLOR),
      bodyFontColor: getColorCodeFromResponse(response.colour, ColorType.BODY_FONT_COLOR)
    },
    supportDetails: {
      email: response.support_details.find((support: any) => support.support_type === 'Email')?.support || '',
      phone: response.support_details.find((support: any) => support.support_type === 'Phone')?.support || '',
    },
    isBrandContestActive: response.is_brand_active,
    closureMessage: response.closure_message,
    appStatus: AppStatus.LIVE,
    redirectingTo: response?.redirection_link || '',
    leagueName: response?.league_name || '',
    dashboardHostedOn: response.dashboard_hosted_on || DashboardHostingPlatform.PARTNER,
    registrationChannel: response.registration_channel || RegistrationChannel.SELF,
    externalRegistrationDetails: {
      url: response.external_registration_page_url,
      buttonText: response.external_registration_button_text,
    },
    isOneContestBrand: response.is_one_contest_brand || false,
    checkContestEnrollmentOnLogin: response.check_contest_enrollment_on_login || false,
    showBrandTermsAndConditionsModal: response.show_brand_terms_and_conditions_popup || false,
    continueJourneyButtonText: response.continue_journey_button_text || '',
    continueJourneyRedirectionLink: response.continue_journey_redirection_link || '',
    showUploadSubmissionPopup: response.show_upload_submission_popup || false,
    submissionUploadStartDate: response.upload_start_date || '',
    isRegistrationClosed: response.is_registration_closed || false,
    registrationClosedMessageHTML: response.registration_closed_message || '',
    isResultsAvailable: response.is_results_available || false,
    resultPageUrl: response.result_page_url || '',
    showOnlyPartnersLogoInHeader: response.show_only_brand_logo || false,
    allowEnrollmentWithOutRegistrationFormFillUp: response.allow_enrollment_without_registration_form_fill_up || false,
  }
}