import React, { Suspense, lazy, useCallback, useEffect } from "react";
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import AppLoader from "../components/AppLoader";
import ErrorPage from "../pages/ErrorPage";
import AuthLayout from "./layout/auth";
import AuthGuard from "./layout/account/AuthGuard";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAppsRedirectionLink,
  selectAppStatus,
  selectDashboardHostedOn,
  selectIsBrandContestActive,
} from "../utils/store/slice/appConfig";
import { AppStatus, DashboardHostingPlatform } from "../models";
import SupportDetails from "../components/SupportDetails";
import LocalStorage from "../services/LocalStorage";
import CookiesService from "../services/CookiesService";
import { resetUserData } from "../utils/store/slice/userData";

const Home = lazy(() => import("../pages/home"));
const AccountLayout = lazy(() => import("./layout/account"));
const CompetitionDetails = lazy(() => import("../pages/competition-details"));

const LoginOrRegister = lazy(() => import("../pages/auth/loginOrRegister"));
const BasicUserDetailsForm = lazy(
  () => import("../pages/account/basicUserDetailsForm"),
);

const TermsAndConditions = lazy(() => import("../pages/terms-and-conditions"));
const ContestOver = lazy(() => import("../pages/contest-over"));

const Results = lazy(() => import("../pages/resultsPage"));
const PopularChoiceAward = lazy(() => import("../pages/popularChoiceAward"));

const ComingSoon = lazy(() => import("../pages/ComingSoon"));

const ArtventureUmbrellaPage = lazy(
  () => import("../pages/umbrella-page/artventure"),
);

const BestsellerBrandsStoreDetails = lazy(
  () => import("../pages/bestseller-brands-store-details"),
);

const SlotBooking = lazy(() => import("../pages/slot-booking"));

interface ScrollToTopProps {
  children: React.ReactNode;
}

const ScrollToTop: React.FC<ScrollToTopProps> = (props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

const AppRouter = () => {
  const isBrandContestActive = useSelector(selectIsBrandContestActive);
  const appStatus = useSelector(selectAppStatus);
  const appRedirectionLink = useSelector(selectAppsRedirectionLink);
  const dashboardHostedOn = useSelector(selectDashboardHostedOn);

  const userDataDispatch = useDispatch();

  const redirectToLink = useCallback(() => {
    if (appRedirectionLink) {
      window.location.href = appRedirectionLink;
    } else {
      window.location.href = "/";
    }
  }, [appRedirectionLink]);

  const handleAppStatus = useCallback(() => {
    if (appStatus === AppStatus.IN_DEVELOPMENT) {
      return <ComingSoon test="Dev" />;
    } else if (appStatus === AppStatus.IN_MAINTENANCE) {
      return <ComingSoon test="Maintain" />;
    } else if (appStatus === AppStatus.REDIRECTING) {
      redirectToLink();
      return <AppLoader />;
    } else {
      return null;
    }
  }, [appStatus, redirectToLink]);

  const [appName, setAppName] = React.useState<string>("");

  useEffect(() => {
    setAppName(window.location.host.split(".")[0]);
  }, []);

  const getHomePageComponent = useCallback(() => {
    switch (appName) {
      case "artventure":
        return <ArtventureUmbrellaPage />;
      default:
        return <Home />;
    }
  }, [appName]);

  const isUserLoggedIn = Boolean(LocalStorage.get.accessToken());

  const logoutUser = useCallback(() => {
    LocalStorage.clear();
    userDataDispatch(resetUserData());
    CookiesService.clear.geoLocation();
    window.location.href = "/";
  }, [userDataDispatch]);

  const renderOfflineContestInfoSection = useCallback(
    () => (
      <div className="w-100 vh-100 d-flex flex-column gap-3 justify-content-center align-items-center p-3">
        <h1 className="m-0">This is an offline contest</h1>
        <p className="text-center m-0 fs-5">
          You can only register here, if you have already registered for the
          contest and want to know more details about it.
          <br />
          <SupportDetails
            type="whatsapp"
            message="Reach out to us on WhatsApp "
          />
        </p>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <Link to="/registration" className={"primary-button"}>
            Go To Registration Page
          </Link>
          {isUserLoggedIn && (
            <button
              className="primary-transparent-button mt-4"
              onClick={logoutUser}
            >
              Logout
            </button>
          )}
        </div>
      </div>
    ),
    [isUserLoggedIn, logoutUser],
  );

  if (!isBrandContestActive) {
    return <ContestOver />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<AppLoader />}>
        {handleAppStatus()}
        <ScrollToTop>
          <Routes>
            <Route path="/" element={getHomePageComponent()} />
            <Route
              path="/registration"
              element={<Home hasRegistrationForm />}
            />

            <Route
              path="/authentication"
              element={<AuthLayout children={<LoginOrRegister />} />}
            />

            <Route
              path="/account/basic-details"
              element={
                dashboardHostedOn === DashboardHostingPlatform.NONE ? (
                  renderOfflineContestInfoSection()
                ) : (
                  <AuthGuard
                    component={
                      <AuthLayout children={<BasicUserDetailsForm />} />
                    }
                  />
                )
              }
            />

            <Route
              path="/account/*"
              element={
                dashboardHostedOn === DashboardHostingPlatform.NONE ? (
                  renderOfflineContestInfoSection()
                ) : (
                  <AuthGuard component={<AccountLayout />} />
                )
              }
            />
            <Route
              path="/competition-details/:competitionId"
              element={<CompetitionDetails />}
            />

            <Route
              path="terms-and-conditions"
              element={
                <div className="w-100 pt-4 pb-3">
                  <TermsAndConditions />
                </div>
              }
            />
            <Route path="results" element={<Results />} />
            <Route
              path="results/winners-entries"
              element={<Results showWinnerEntries />}
            />
            <Route
              path="popular-choice-award"
              element={<PopularChoiceAward />}
            />
            <Route path="stores" element={<BestsellerBrandsStoreDetails />} />
            <Route
              path="slot-booking"
              element={
                dashboardHostedOn !== DashboardHostingPlatform.NONE ? (
                  <ErrorPage errorCode={404} />
                ) : (
                  <AuthGuard component={<SlotBooking />} />
                )
              }
            />
            <Route path="*" element={<ErrorPage errorCode={404} />} />
          </Routes>
        </ScrollToTop>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
