import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectPartnerType } from "../../utils/store/slice/appConfig";
import { PartnerType } from "../../models";

interface SupportDetailsProps {
  type: "whatsapp" | "email" | "phone";
  message?: string | React.ReactNode;
}

const SupportDetails: React.FC<SupportDetailsProps> = ({
  type,
  message = "Have any queries? Reach out to us on",
}) => {
  const partnerType = useSelector(selectPartnerType);

  const getWhatsAppSupportNumber = useCallback(() => {
    if (partnerType === PartnerType.SCHOOL) {
      return process.env.REACT_APP_WHATSAPP_SUPPORT_NUMBER_SCHOOL;
    } else {
      return process.env.REACT_APP_WHATSAPP_SUPPORT_NUMBER_BRAND;
    }
  }, [partnerType]);

  const getEmailSupport = () => process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS;

  if (type === "whatsapp") {
    return (
      <span className="mt-3">
        {message}
        <a
          href={`https://api.whatsapp.com/send?phone=${getWhatsAppSupportNumber()}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          +{getWhatsAppSupportNumber()}
        </a>
      </span>
    );
  }

  if (type === "email") {
    return (
      <span className="mt-3">
        {message}
        <a href={`mailto:${getEmailSupport()}`}>{getEmailSupport()}</a>
      </span>
    );
  }

  return null;
};

export default SupportDetails;
