import React, { useCallback, useEffect, useState } from 'react';
import AppRouter from './routing/AppRouter';
import AppConfigService from './services/appConfig/appConfig';
import { setCSSColorVariables } from './utils/themingFunction';
import { AppConfig } from './models';
import { useDispatch, useSelector } from 'react-redux';
import AppLoader from './components/AppLoader';
import { selectPartnerDomain, setAppConfig, setPartnerDomain } from './utils/store/slice/appConfig';
import PlatformSwitcher from './PlatformSwitcher';
import { platforms } from './utils/platformNames';
import mixpanel from 'mixpanel-browser';
import { LOCAL_STORAGE_KEYS } from './utils/constants';
import AppToast from './components/AppToast';
import setFontInApplication from './utils/customFontEngine/appFontSetterEngine';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import LocalStorage from './services/LocalStorage';
import usePreventZoomOnFocus from './utils/customHooks/usePreventZoomOnFocus';

import './fontFaces.css';
import './App.scss';
import Cookies from 'js-cookie';

const App: React.FC = () => {
  const [isAppConfigLoading, setIsAppConfigLoading] = useState(false);

  const appConfigDispatch = useDispatch();
  const platformDomain = useSelector(selectPartnerDomain);

  usePreventZoomOnFocus();

  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN!!, {
    debug: process.env.NODE_ENV === 'development',
    ignore_dnt: true,
    save_referrer: true,
    persistence: 'localStorage'
  });

  const isAppOnLocalOrStaging = useCallback((): boolean => {
    try {
      const currentAppHost = window.location.hostname;
      const stagingAppURL = process.env.REACT_APP_DEV_APP_URL || '';
      const localAppURL = process.env.REACT_APP_LOCAL_APP_URL || '';

      const stagingAppHostname = new URL(stagingAppURL);
      const localAppHostname = new URL(localAppURL);

      return currentAppHost === stagingAppHostname.hostname || currentAppHost === localAppHostname.hostname;
    } catch (e) {
      return false;
    }
  }, []);

  useEffect(() => {
    let partnerDomainName = platformDomain || platforms[0].value;

    if (process.env.NODE_ENV === 'production' && !isAppOnLocalOrStaging()) {
      partnerDomainName = window.location.hostname.split('.')[0];
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.partnerDomain, partnerDomainName);

    if (partnerDomainName && partnerDomainName !== '') {
      setFontInApplication(partnerDomainName);
      setIsAppConfigLoading(true);
      AppConfigService.getAppConfig(partnerDomainName)
        .then((res) => {
          const responseData: AppConfig = res.data.data;
          appConfigDispatch(setAppConfig(responseData));
          appConfigDispatch(setPartnerDomain(partnerDomainName));

          // window.document.title = `${responseData.name} x wizkidscarnival`;

          const favicon = document.getElementById('favicon') as HTMLLinkElement;
          if (favicon) {
            favicon.href = responseData.logo.smallLogo.url;
          }

          const colors = responseData.color;
          setCSSColorVariables(colors);
        })
        .catch((err) => {
          // TODO: Show error message to user
          console.error('Error fetching app config', err);
        })
        .finally(() => {
          // Hide App loader
          setIsAppConfigLoading(false);
        });
    } else {
      setIsAppConfigLoading(false);
    }
  }, [platformDomain, isAppOnLocalOrStaging, appConfigDispatch]);

  useEffect(() => {
    const generateFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      LocalStorage.set.anonymousUserId(result.visitorId);
    };

    generateFingerprint();
  }, []);

  useEffect(() => {
    const mixpanelUniqueId = Cookies.get('mixpanelDistinctId');
    if (mixpanelUniqueId) {
      mixpanel.identify(mixpanelUniqueId);
    }
  }, []);

  return (
    <div className='d-flex min-vh-100'>
      <AppToast />
      {isAppConfigLoading ? (
        <AppLoader
          message={
            <div className='text-center'>
              Hey! Please wait,
              <br />
              We are getting things ready for you.
            </div>
          }
        />
      ) : (
        <AppRouter />
      )}
      {isAppOnLocalOrStaging() && <PlatformSwitcher />}
    </div>
  );
};

export default App;
